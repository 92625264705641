///
/// Solid State by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out');
		background-color: transparent;
		border-radius: _size(border-radius);
		border: 0;
		box-shadow: inset 0 0 0 2px _palette(border);
		color: _palette(fg-bold) !important;
		cursor: pointer;
		display: inline-block;
		font-family: _font(family-heading);
		font-size: 0.8em;
		font-weight: _font(weight-heading-bold);
		height: 3.75em;
		letter-spacing: _font(kern-heading);
		line-height: 3.75em;
		padding: 0 2.25em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;

		&:hover {
			background-color: _palette(border-bg);
		}

		&:active {
			background-color: _palette(border2-bg);
		}

		&.icon {
			&:before {
				margin-right: 0.5em;
				color: _palette(fg-light);
			}
		}

		&.special {
			background-color: _palette(accent);
			box-shadow: none;

			&:hover {
				background-color: desaturate(lighten(_palette(accent), 3), 1.5);
			}

			&:active {
				background-color: saturate(darken(_palette(accent), 3), 1.5);
			}

			&.icon {
				&:before {
					color: mix(_palette(fg-bold), _palette(accent), 25%);
				}
			}
		}

		&.fit {
			display: block;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
			width: 100%;
		}

		&.small {
			font-size: 0.6em;
		}

		&.big {
			font-size: 1em;
		}

		&.disabled,
		&:disabled {
			opacity: 0.25;
		}

		@include breakpoint(xsmall) {
			padding: 0;
		}
	}