///
/// Solid State by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Features */

	.features {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		margin: 0 0 _size(element-margin) 0;

		article {
			@include padding(1.75em, 1.75em);
			background-color: desaturate(lighten(_palette(bg), 3), 1.5);
			border-radius: _size(border-radius);
			margin: (_size(section-spacing, large) * 0.5) _size(section-spacing, large) (_size(section-spacing, large) * 0.5) 0;
			width: calc(50% - #{_size(section-spacing, large) * 0.5});

			&:nth-child(2n) {
				margin-right: 0;
			}

			.image {
				border-radius: _size(border-radius) _size(border-radius) 0 0;
				display: block;
				margin-bottom: 1.75em;
				margin-left: -1.75em;
				margin-top: -1.75em;
				position: relative;
				width: calc(100% + #{3.5em});

				img {
					border-radius: _size(border-radius) _size(border-radius) 0 0;
					width: 100%;
				}
			}
		}

		@include breakpoint(medium) {
			article {
				margin: (_size(section-spacing, medium) * 0.5) _size(section-spacing, medium) (_size(section-spacing, medium) * 0.5) 0;
				width: calc(50% - #{_size(section-spacing, medium) * 0.5});
			}
		}

		@include breakpoint(small) {
			article {
				@include padding(1.5em, 1.5em);
				margin: (_size(section-spacing, small) * 0.5) _size(section-spacing, small) (_size(section-spacing, small) * 0.5) 0;
				width: calc(50% - #{_size(section-spacing, small) * 0.5} - 1px);

				.image {
					margin-bottom: 1.5em;
					margin-left: -1.5em;
					margin-top: -1.5em;
					width: calc(100% + #{3em});
				}
			}
		}

		@include breakpoint(xsmall) {
			display: block;

			article {
				width: 100%;
				margin: 0 0 _size(element-margin) 0 !important;
			}
		}
	}