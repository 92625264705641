///
/// Solid State by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Banner */

	#banner {
		@include padding(10em, 0, (0, 0, _size(wrapper-edges, large) * -0.5, 0));

		.inner {
			margin: 0 auto;
			width: _size(inner);
		}

		.logo {
			@include vendor('transition', (
				'opacity 2s ease',
				'transform 1s ease',
			));
			@include vendor('transform', 'translateY(0)');
			opacity: 1;
			margin: 0 0 (_size(element-margin) * 0.65) 0;

			.icon {
				border-radius: 100%;
				border: solid 2px _palette(border);
				cursor: default;
				display: inline-block;
				font-size: 2em;
				height: 2.25em;
				line-height: 2.25em;
				text-align: center;
				width: 2.25em;
			}
		}

		h2 {
			@include vendor('transition', (
				'opacity 0.5s ease',
				'transform 0.5s ease',
				'filter 0.25s ease',
			));
			@include vendor('transform', 'translateX(0)');
			@include vendor('transition-delay', '0.65s');
			@include vendor('filter', 'blur(0)');
			opacity: 1;
			border-bottom: solid 2px _palette(border);
			font-size: 2.25em;
			margin-bottom: _size(element-margin) * 0.4;
			padding-bottom: _size(element-margin) * 0.2;
		}

		p {
			@include vendor('transition', (
				'opacity 0.5s ease',
				'transform 0.5s ease',
				'filter 0.25s ease',
			));
			@include vendor('transform', 'translateX(0)');
			@include vendor('transition-delay', '0.8s');
			@include vendor('filter', 'blur(0)');
			opacity: 1;
			font-family: _font(family-heading);
			font-size: 1em;
			font-weight: _font(weight-heading);
			letter-spacing: _font(kern-heading);
			line-height: 2;
			text-transform: uppercase;
		}

		@include breakpoint(large) {
			@include padding(7em, 0, (0, 0, _size(wrapper-edges, large) * 0.5, 0));

			background-color:		_palette(bg);

			background-image:		linear-gradient(to top, transparentize(_palette(bg), 0.2), transparentize(_palette(bg), 0.2)),
									url('../../images/bg.jpg');

			background-size:		auto,
									cover;

			background-position:	center,
									center;

		 	margin-bottom: (_size(wrapper-edges, large) * -1);
		}

		@include breakpoint(medium) {
			@include padding(12em, 3em, (0, 0, _size(wrapper-edges, medium) * 0.5, 0));

		 	margin-bottom: (_size(wrapper-edges, medium) * -1);

			.inner {
				width: 100%;
			}
		}

		@include breakpoint(small) {
			@include padding(5em, 2em, (0, 0, _size(wrapper-edges, small) * 0.5, 0));

		 	margin-bottom: (_size(wrapper-edges, small) * -1);

			.logo {
				margin: 0 0 (_size(element-margin) * 0.5) 0;

				.icon {
					font-size: 1.5em;
				}
			}

			h2 {
				font-size: 1.5em;
			}

			p {
				font-size: 0.8em;
			}
		}

		body.is-loading & {
			.logo {
				@include vendor('transform', 'translateY(0.5em)');
				opacity: 0;
			}

			h2 {
				opacity: 0;
				@include vendor('transform', 'translateX(0.25em)');
				@include vendor('filter', 'blur(2px)');
			}

			p {
				opacity: 0;
				@include vendor('transform', 'translateX(0.5em)');
				@include vendor('filter', 'blur(2px)');
			}
		}
	}